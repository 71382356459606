/* Container for the entire Employee Task component */
.employee-task-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Grid container for displaying shops */
.shop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 10px;
}

/* Individual shop cards */
.shop-card {
  padding: 15px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: transform 0.2s;
}

/* Radio buttons within shop cards */
.shop-card input[type="radio"] {
  margin-right: 10px;
}

/* Shop labels inside the cards */
.shop-label {
  display: block;
  font-weight: bold;
  color: #333;
}

/* Shop card hover effect */
.shop-card:hover {
  transform: translateY(-5px);
}

/* Product list styling */
.product-list {
  margin-top: 20px;
}

/* Individual product item */
.product-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Count buttons and input */
.count-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.count-button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.count-input {
  width: 60px;
  text-align: center;
}

/* Disabled button styling */
.count-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Product info styling */
.product-info {
  font-size: 14px;
  color: #555;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px;
}

.grid-item {
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  text-align: center;
  background: #f9f9f9;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.grid-item:hover {
  transform: scale(1.05);
  background-color: #e8f5fe;
}

.task-tabs {
  display: flex;
  justify-content: space-around;
  background-color: #f4f4f4;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.tab-item {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.tab-item.active {
  background-color: #007bff;
  color: white;
}

.tab-item:hover {
  background-color: #e0e0e0;
}

.task-content {
  margin-top: 20px;
  padding: 20px;
}
