.navbar {
  background-color: #f1f1e7;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo and Links on the Left 
  .nav-left {
    display: flex;
    align-items: center;
  }*/

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: rgb(34, 30, 30);
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #00bcd4;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav-user-icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
}

.nav-logout {
  list-style: none;
  cursor: pointer;
}

.nav-logout a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-logout a:hover {
  color: #ff5722;
}
.nav-logo {
  width: 150px;
  height: auto;
  max-height: auto;
}
.user-menu {
  position: relative;
  display: inline-block;
}

.nav-user-icon {
  font-size: 30px;
  color: #7d9b33;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #444;
  min-width: 100px;
  padding: 8px 0;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: rgb(196, 163, 163);
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: center;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #00bcd4;
}

.user-menu:hover .dropdown-content {
  display: block;
}

.cart-icon,
.bell-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cart-count,
.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff2922;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  border: 2px solid #f1f1e7;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  overflow: hidden;
  background-color: #f9f9f9;
}

.language-switcher button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  /* transition: background-color 0.3s, color 0.3s; */
  margin-bottom: 0px;
}

.language-switcher button:not(:last-child) {
  border-right: 1px solid #ccc;
}

.language-switcher button.active {
  background-color: #134d14;
  color: #ccc;
  /* border: 1px solid #007bff; */
  border-radius: 5px;
}

@media (max-width: 600px) {
  .nav-logo {
    width: 100px;
    max-height: 40px;
  }
}
