.task-assignment {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.task-assignment h1 {
  text-align: center;
  color: #333;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  gap: 10px;
}

.filters label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #555;
}

.filters input,
.filters select {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 180px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-buttons button {
  padding: 8px 12px;
  background-color: #134d14;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filter-buttons button:hover {
  background-color: #134d14;
}

.select-employee-btn {
  background-color: #28a745;
}

.select-employee-btn:hover {
  background-color: #218838;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.order-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.2s ease;
}

.order-card:hover {
  transform: scale(1.02);
}

.order-summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-summary div {
  color: #333;
  font-size: 14px;
}

.order-summary button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

.order-summary button:hover {
  background-color: #134d14;
}

.order-details {
  margin-top: 15px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
}

.order-details h4 {
  margin: 0 0 10px;
  font-size: 16px;
  color: #444;
}

.order-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-details li {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}

.no-data {
  text-align: center;
  font-size: 16px;
  color: #555;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px); /* Background blur effect */
  z-index: 999; /* Ensure it sits below the popup but above the rest of the content */
}

.employee-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  max-height: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000; /* Above the overlay */
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.popup-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
}

.popup-body {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.employee-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #ddd;
}

.extra-btn,
.assign-btn {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.extra-btn {
  background-color: #134d14;
  color: white;
}

.assign-btn {
  background-color: #28a745;
  color: white;
}

.employee-popup .employee-list .employee-card {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.employee-popup .employee-list .employee-card:hover {
  background-color: #e9ecef;
}

.employee-popup .employee-list .employee-card .employee-name {
  font-weight: bold;
  color: #333;
}

.employee-popup .employee-list .employee-card .employee-info {
  color: #777;
}

.employee-popup .employee-list .employee-card .assign-btn {
  background-color: #007bff;
}

.employee-popup .employee-list .employee-card .assign-btn:hover {
  background-color: #0056b3;
}

/* Additional styles for the Extra Form */
.extra-product-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  max-height: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1010; /* Should be above employee popup */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.extra-product-form h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.extra-product-form .form-group {
  margin-bottom: 15px;
}

.extra-product-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.extra-product-form select,
.extra-product-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.extra-product-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.extra-product-form button:hover {
  background-color: #0056b3;
}

.extra-products-list {
  margin-top: 15px;
}

.extra-products-list h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.extra-products-list ul {
  list-style: none;
  padding: 0;
}

.extra-products-list li {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}

.extra-product-form .close-btn {
  font-size: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: #555;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.employee-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  max-height: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.popup-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
}

.popup-body {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.employee-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #ddd;
}

.extra-btn,
.assign-btn {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.extra-btn {
  background-color: #134d14;
  color: white;
}

.assign-btn {
  background-color: #134d14;
  color: white;
}

.assigned {
  border: 2px solid green;
}

.unassigned {
  border: 2px solid red;
}

.task-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.task-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.task-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.task-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
}
