.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
}

.close-button:hover {
  color: red;
}

.checkout-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkout-form label {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
}

.checkout-form input,
.checkout-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.checkout-form textarea {
  resize: vertical;
  min-height: 80px;
}

.checkout-form button.submit-checkout {
  background-color: #134d14;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-form button.submit-checkout:hover {
  background-color: #008f7a;
}

.checkout-form p {
  font-size: 12px;
  margin: 0;
}

@media (max-width: 600px) {
  .checkout-form {
    padding: 15px;
  }

  .checkout-form label {
    font-size: 13px;
  }

  .checkout-form input,
  .checkout-form textarea {
    font-size: 13px;
  }

  .checkout-form button.submit-checkout {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.cart-item {
  display: flex;
  align-items: flex-start; /* Align items at the start for better vertical spacing */
  justify-content: space-between;
  padding: 20px 0; /* Increase padding between items */
  border-bottom: 1px solid #eaeaea;
  gap: 15px; /* Add some gap between child elements */
}

.cart-item:last-child {
  border-bottom: none;
}

.product-image {
  width: 100px; /* Slightly larger for better visibility */
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px; /* Add more space between the image and details */
}

.product-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space out product details */
}

.product-details h4 {
  margin: 0;
  font-size: 18px; /* Increase font size for better readability */
  color: #222;
}

.count-delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px; /* Increase spacing between count controls and delete icon */
}

.count-update {
  display: flex;
  align-items: center;
  gap: 10px; /* Add spacing between buttons and count */
  margin: 0;
}

.delete-icon {
  color: #d9534f;
  cursor: pointer;
  font-size: 20px;
}

.cart-actions {
  max-width: 800px;
  margin: 30px auto; /* Add more margin for spacing */
  display: flex;
  justify-content: space-between;
}

.cart-actions button {
  padding: 15px 20px; /* Increase button size for better usability */
  font-size: 18px; /* Make text slightly larger */
}

@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; /* Add space between elements in vertical view */
  }

  .product-image {
    margin: 0 auto 10px; /* Center image in mobile view */
  }

  .cart-actions {
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Space out buttons in mobile view */
  }

  .cart-actions button {
    width: 100%; /* Buttons take full width in mobile view */
  }
}
