.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h2 {
  margin-bottom: 20px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-width: 100px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.card {
  background-color: #134d14;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.card h3 {
  margin-bottom: 10px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.chart {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.chart {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  overflow-x: auto;
  max-width: 100%;
}

.chart canvas {
  max-width: 100%;
}

.tables {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.table {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

table th {
  background-color: #134d14;
  color: white;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }

  .cards {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .charts {
    grid-template-columns: 1fr;
  }

  .tables {
    grid-template-columns: 1fr;
  }

  .table {
    padding: 10px;
  }

  table th,
  table td {
    padding: 8px;
    font-size: 14px;
  }

  .card h3,
  .chart h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .filters select {
    min-width: auto;
    font-size: 14px;
  }

  /* .cards {
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px; 
  }

  .card {
    padding: 10px; 
    font-size: 12px; 
    border-radius: 6px; 
  } */

  .chart {
    padding: 15px;
  }

  .table {
    padding: 10px;
  }

  table th,
  table td {
    font-size: 12px;
  }
}
