.pod-form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.pod-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pod-form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.pod-form-group input,
.pod-form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.pod-order-details-grid {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.pod-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.pod-grid-row label {
  margin-right: 10px;
}

.pod-submit-btn {
  background-color: #134d14;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pod-submit-btn:hover {
  background-color: #134d14;
}

/* Table styles */
.pod-table-container {
  margin: 20px;
  overflow-x: auto;
}

.pod-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pod-table th,
.pod-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.pod-table th {
  background-color: #134d14;
  font-weight: bold;
}

.pod-table td {
  background-color: #fff;
}

.action-icon {
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.action-icon:hover {
  color: #134d14;
}

/* Responsive styles */
@media (max-width: 768px) {
  .pod-form-container,
  .pod-order-details-grid {
    padding: 15px;
  }

  .pod-grid-row {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .pod-table-container {
    margin: 10px;
  }

  .pod-table th,
  .pod-table td {
    font-size: 12px;
    padding: 8px;
  }

  .pod-submit-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .pod-form-container,
  .pod-order-details-grid {
    padding: 10px;
  }

  .pod-form-group input,
  .pod-form-group select {
    font-size: 14px;
  }

  .pod-table th,
  .pod-table td {
    font-size: 10px;
    padding: 5px;
  }

  .action-icon {
    font-size: 16px;
  }
}
