.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.notification-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.notification-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.notification-item.unread {
  font-weight: bold;
}

.notification-details {
  padding: 10px;
}

.notification-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.notification-actions label {
  display: flex;
  align-items: center;
}

.notification-actions .delete-btn {
  background: #d9534f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.notification-actions .delete-btn:hover {
  background: #c9302c;
}

.notification-item input[type="checkbox"] {
  margin-right: 10px;
}

.notification-text {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
