.cus-form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.cus-close-button {
  position: relative; 
  top: -10px; 
  right: -10px; 
  float: right;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: black;
}

.cus-form {
  display: flex;
  flex-direction: column;
}

.cus-form-field {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 15px; */
}

.cus-form-field label {
  margin-bottom: 3.5px;
  font-weight: bold;
}

.cus-form-field input,
.cus-form-field select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.cus-submit-button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cus-submit-button:hover {
  background-color: #218838;
}

.cus-username-availability {
  margin-top: 5px;
  font-size: 12px;
}

.cus-error {
  color: red;
}

.cus-success {
  color: green;
}
