.orders-container {
  padding: 20px;
}

.orders-cards-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.orders-card {
  width: 200px;
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
  box-shadow: 0 2px 4px #7d9b33;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.vendor-list {
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.vendor-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.vendor-item input {
  margin-right: 10px;
}

.next-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #f9f9f9;
  width: 400px;
  margin: 20px auto;
  position: relative;
}

/* .form-group {
  margin-bottom: 15px;
} */

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-close-btn {
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.product-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.grid-inputs {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin: 10px 0;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.input-group input {
  width: 60px;
  padding: 5px;
  text-align: center;
}

.orders-container {
  padding: 20px;
}

.orders-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.orders-card {
  flex: 1 1 calc(100% - 40px); /* Full width on small screens */
  max-width: 200px; /* Limit the width on larger screens */
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
  box-shadow: 0 2px 4px #7d9b33;
}

.pagination span {
  margin: 0 10px; /* Adjust the spacing as needed */
  cursor: pointer;
}

.pagination span[disabled] {
  cursor: not-allowed;
  opacity: 0.5; /* Optional: To indicate the disabled state */
}

.product-row span {
  margin: 0 10px;
}

.product-row button {
  gap: 20px;
}

.whatsapp-check {
  margin-bottom: 10px;
  gap: 20px;
}

.whatsapp-check label {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjust the spacing between the checkbox and label */
}

.whatsapp-check input[type="checkbox"] {
  margin: 0; /* Removes default margin applied by the browser */
}

.product-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px; /* Adjust for spacing */
  padding: 8px; /* Optional padding */
}

.product-row input[type="checkbox"] {
  margin: 0;
}

.product-row span {
  flex: 1; /* Adjusts to take available space */
  font-size: 30px; /* Make text mobile-friendly */
  text-align: left;
}

.crate-count {
  display: flex;
  align-items: center;
}

.crate-count input {
  width: 16px; /* Adjust width as needed */
  text-align: center;
  padding: 4px;
}

.product-row button {
  min-width: 30px; /* Ensures buttons remain tappable */
  padding: 4px;
}

@media (max-width: 768px) {
  .product-row {
    flex-direction: row; /* Keep everything in a single row */
  }

  .product-row span {
    font-size: 12px; /* Slightly smaller for compactness */
  }
}

@media (max-width: 768px) {
  .orders-card {
    flex: 1 1 100%; /* Single-column layout */
  }

  table {
    font-size: 0.8em;
  }

  .pagination span {
    font-size: 0.9em;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .form-container {
    width: 100%; /* Full width for small screens */
    padding: 10px;
  }

  .orders-cards-container {
    gap: 10px;
  }

  .orders-card {
    padding: 15px;
  }

  table {
    display: block;
    overflow-x: auto; /* Allow horizontal scrolling */
    white-space: nowrap;
  }

  table thead {
    display: none; /* Hide headers for small screens */
  }

  table tr {
    display: block;
    margin-bottom: 10px;
  }

  table td {
    display: block;
    text-align: right;
    padding: 5px 10px;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .pagination {
    flex-direction: column;
    align-items: center;
    gap: 7px;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}

table thead {
  background-color: #f4f4f4;
}

table th,
table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  table thead {
    display: table-header-group;
  }

  table tbody {
    display: table-row-group;
  }

  table tr {
    display: table-row;
  }

  table th,
  table td {
    display: table-cell;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 576px) {
  table {
    font-size: 0.9em;
  }

  .pagination span {
    font-size: 0.8em;
    padding: 5px;
    gap: 7px;
  }
}
