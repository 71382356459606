.milk-products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  position: relative;
}

.filter-toggle-btn {
  display: none;
  background-color: #7d9b33;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  gap: 8px;
}

.filter-toggle-btn svg {
  margin-right: 8px;
}

.filter-menu {
  width: 250px;
  background-color: #f8f8f8;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #7d9b33;
  border-radius: 5px;
  height: fit-content;
  cursor: pointer;
}

.filter-menu h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.filter-menu input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.filter-menu ul {
  list-style: none;
  padding: 0;
}

.filter-menu ul li {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.filter-menu ul li:last-child {
  border-bottom: none;
}

/* Product Grid */
.products {
  flex-grow: 1;
  min-width: 0;
}

.products h2 {
  margin-bottom: 20px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px;
  padding: 10px;
}

.product-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #7d9b33;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  height: 100%;
}

.product-content {
  flex-grow: 1;
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-card h4 {
  margin: 10px 0 5px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-controls button {
  padding: 5px 10px;
  border: 1px solid #7d9b33;
  background: #fff;
  color: #7d9b33;
  cursor: pointer;
  font-size: 16px;
  margin-top: 11px;
}

.quantity-controls span {
  margin: 0 10px;
  font-size: 16px;
  text-align: center;
}

.add-to-cart {
  margin-top: auto;
  padding: 10px 20px;
  border: none;
  background-color: #7d9b33;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  align-self: center;
}

.add-to-cart:hover {
  background-color: #6a872c;
}

@media (max-width: 768px) {
  .filter-toggle-btn {
    display: flex;
    position: relative;
  }

  .filter-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 250px;
    height: 100vh;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    z-index: 999;
  }

  .filter-menu.open {
    right: 0;
    top: 0;
  }

  .close-filter-btn {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }

  .close-filter-btn:hover {
    color: #ff0000;
  }

  .milk-products-container {
    padding-top: 60px;
  }

  .product-card {
    padding: 12px;
  }

  .product-card h4 {
    font-size: 14px;
  }

  .quantity-controls button {
    font-size: 14px;
    padding: 4px 8px;
  }

  .quantity-controls span {
    font-size: 14px;
  }
}

@media (min-width: 769px) {
  .close-filter-btn {
    display: none;
  }
}
