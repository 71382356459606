body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

header {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  text-align: center;
}

nav {
  background-color: #333;
  padding: 10px;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

aside {
  background-color: #f4f4f4;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  transition: width 0.3s ease;
  width: 200px; /* Default expanded width */
}

aside.open {
  width: 200px;
}

aside.collapsed {
  width: 60px; /* Collapsed width */
}

aside button {
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  cursor: pointer;
}

aside ul {
  list-style: none;
  padding: 0;
}

aside ul li {
  margin: 10px 0;
}

aside ul li a {
  text-decoration: none;
  color: #fcf8f8;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

main {
  padding: 20px;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

main.sidebar-open {
  margin-left: 240px;
}

main.sidebar-collapsed {
  margin-left: 60px;
}

.login-container {
  margin: 50px auto;
  max-width: 300px;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-container button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #45a049;
}

button {
  background-color: #134d14;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:focus {
  outline: 2px solid #0e3a0e;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding-right: 2rem;
}

.password-toggle-icon {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
  top: 0.7rem;
}

@media (max-width: 767px) {
  aside {
    width: 0;
    transition: width 0.3s ease;
  }

  aside.open {
    width: 200px;
  }

  aside.collapsed {
    width: 60px;
  }

  main {
    margin-left: 0;
  }

  main.sidebar-collapsed {
    margin-left: 60px; /* Adjust as needed for smaller screens */
  }
}
