.sidebar {
  width: 0;
  transition: width 0.3s ease;
  background-color: #7d9b33;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 999;
  color: white;
  padding-top: 10px;
  box-sizing: border-box;
}

.sidebar.open {
  width: 200px;
}

.close-button {
  cursor: pointer;
  padding: 10px;
  text-align: right;
  font-size: 24px;
}

.sidebar ul {
  list-style: none;
  padding: 0 20px;
  margin: 0;
}

.sidebar li {
  margin-bottom: 15px;
  display: block;
}

.sidebar button {
  background: none;
  border: none;
  color: white;
  text-align: left;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0;
  display: block;
}

@media (min-width: 768px) {
  .sidebar {
    width: 250px;
    transition: margin-left 0.3s ease;
  }

  .sidebar.open {
    margin-right: 20px;
    width: 250px;
  }

  .main-content {
    margin-left: 20px;
    transition: margin-left 0.3s ease;
  }

  .main-content.collapsed {
    margin-left: 0px; /* Adjust according to your collapsed sidebar width */
  }
}

/* Media query for small screens */
@media (max-width: 767px) {
  .sidebar {
    width: 100%;
    /* height: auto; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #7d9b33;
  }

  .sidebar.open {
    width: 70%;
    /* height: auto; */
  }

  .close-button {
    padding: 20px;
  }

  .main-content {
    margin-left: 0;
  }
}

.sidebar {
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}
