.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.action-button {
  padding: 12px 20px;
  background-color: #134d14;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.action-button:hover {
  background-color: #0f3d10;
}

.filters {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filters select {
  padding: 8px;
  border-radius: 4px;
  min-width: 120px;
  font-size: 14px;
}

.summary-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #d0c2b7;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  width: 160px;
  box-shadow: 0px 2px 4px #a08071;
}

.shops-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.shop-item {
  background-color: #e6ffe6;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #7d9b33;
}

.shops-section {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: center;
  }

  .summary-cards {
    flex-direction: column;
    gap: 10px;
  }

  .card {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .shops-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .shop-item {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .filters select,
  .filters input {
    width: 100%;
    padding: 10px;
  }

  .summary-cards {
    flex-direction: column;
  }

  .card {
    font-size: 14px;
    padding: 10px;
  }

  .shop-item {
    padding: 10px;
  }

  .shop-header {
    flex-direction: column;
    gap: 5px;
  }
}
