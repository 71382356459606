.product-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background-color: #fff;
} 

.product-container:hover {
  border-color: #4caf50;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.product-container input[type="checkbox"] {
  margin-right: 5px;
  margin-top: 14px;
}

.product-name {
  flex: 1;
}

.counter-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.counter-container button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #134d14;
  cursor: pointer;
}

.counter-container input {
  width: 23px;
  text-align: center;
  border: 1px solid #ccc;
}
