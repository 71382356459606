.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.open-form-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #134d14;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: #f8f9fa;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin: 10px 0 5px;
  font-size: 14px;
}

input,
select,
button {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button[type="submit"] {
  background-color: #28a745;
  color: white;
  border: none;
}

@media (max-width: 600px) {
  .form-container {
    width: 90%;
  }

  .open-form-btn {
    width: 100%;
  }
}
