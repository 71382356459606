.register-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  padding-top: calc(var(--navbar-height, 60px) + 20px);
  box-sizing: border-box;
}

.form {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.message {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #777;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.input {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input.error {
  border-color: #dc3545;
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

button {
  background-color: #134d14;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  /* width: 100%; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

button:hover {
  background-color: #134d14;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.signin {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.signin a {
  color: #007bff;
  text-decoration: none;
}

.signin a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .form {
    padding: 20px;
    width: 90%;
  }

  .title {
    font-size: 20px;
  }

  .input {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }

  .signin {
    font-size: 12px;
  }

  .register-form-container {
    padding-top: calc(var(--navbar-height, 60px) + 55px);
  }
}

@media (max-width: 480px) {
  .form {
    padding: 15px;
    width: 100%;
  }

  .title {
    font-size: 18px;
  }

  .input {
    font-size: 12px;
  }

  button {
    font-size: 14px;
  }

  .signin {
    font-size: 10px;
  }

  .register-form-container {
    padding-top: calc(var(--navbar-height, 60px) + 55px);
  }
}
