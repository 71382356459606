.area-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.area-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #134d14;
  cursor: pointer;
}

.area-button.selected {
  background-color: #134d14;
  color: #fff;
  border-color: #134d14;
}

.qnt-and-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 5px 0;
  text-align: center;
}

.form-group-paynow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  margin-top: 10px;
}

label {
  margin-bottom: 5px;
}

.submit-download-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payment-options {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 20px;
  margin: 0 30px;
}

.payment-radio {
  display: flex;
  /* align-items: center; */
  gap: 20px;
}

.payment-radio input[type="radio"] {
  margin: 0;
  padding: 0;
  width: auto;
}

.payment-radio label {
  margin: 0;
  padding: 0;
}

.discount label {
  font-weight: 700;
  font-size: 16px;
}

.discount input[type="number"] {
  width: 100px;
  margin: 0 10px;
  text-align: center;
}

.product-item {
  column-gap: 10px;
}

.product-header {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 10px;
}

.product-header input[type="checkbox"] {
  width: 18px;
  height: 28px;
}

.product-header label {
  font-weight: 700;
  font-size: 16px;
}

@media screen and (min-width: 769px) {
  .qnt-and-subtotal {
    flex-direction: row;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: center;
}

.modal-content label {
  font-size: 1rem;
  margin-bottom: 5px;
  display: block;
}

.modal-content input[type="date"],
.modal-content select {
  width: 50%;
  padding: 8px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
  width: 100%;
}

.modal-content button:hover {
  background-color: #45a049;
}

.modal-content button:active {
  transform: scale(0.98);
}

.modal-content button.cancel {
  background-color: #f44336;
}

.modal-content button.cancel:hover {
  background-color: #e53935;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
    max-width: 90%;
    width: 100%;
  }

  .modal-content h2 {
    font-size: 1.25em;
  }

  .modal-content label {
    font-size: 0.9rem;
  }

  .modal-content input[type="date"],
  .modal-content select {
    font-size: 0.9rem;
    padding: 6px;
  }

  .modal-content button {
    font-size: 0.9rem;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 10px;
    max-width: 100%;
  }

  .modal-content h2 {
    font-size: 1.1em;
  }

  .modal-content input[type="date"],
  .modal-content select {
    font-size: 0.85rem;
    padding: 5px;
  }

  .modal-content button {
    font-size: 0.85rem;
    padding: 10px;
  }
}

.modal-content button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 15px;
}

.modal-content button {
  background-color: #4caf50;
  color: white;
  border: 1px solid transparent;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 30%;
}

.modal-content button:hover {
  background-color: #45a049;
}

.modal-content button:active {
  transform: scale(0.98);
}

.modal-content button.cancel {
  background-color: #f44336;
  width: 30%;
}

.modal-content button.cancel:hover {
  background-color: #e53935;
}

@media (max-width: 768px) {
  .modal-content button-container {
    flex-direction: column;
    gap: 10px;
  }

  .modal-content button {
    width: 100%;
  }
}
